import {PagesTitles} from "../constants";
import {PagesId} from "../constants/PAGES_ID";
import {Currency, TicketData, TicketsSearchResult} from "../services/interfaces";

export const getPageMetaById = (id: PagesId) => {
    return {
        title: PagesTitles[id]
    };
};

export const handleErrorException = (e: any): string => {
    let errorMessage: string;
    // Check if the error is an instance of Error
    if (e instanceof Error) {
        errorMessage = e.message; // Standard Error message
    } else if (typeof e === "string") {
        errorMessage = e; // If the error is already a string
    } else {
        errorMessage = JSON.stringify(e); // Fallback for non-standard errors
    }
    return errorMessage;
};

// TODO move in other place maybe to tickets store
export const groupTicketsByDate = (tickets: TicketData[]): TicketsSearchResult[] => {
    const groupedMap = new Map<
        string,
        {
            sortDate: Date;
            tickets: TicketData[];
        }
    >();

    tickets.forEach(ticket => {
        const [datePart] = ticket.date.split(" ");
        const date = new Date(datePart);

        const dateKey = date.toLocaleString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric"
        });

        if (!groupedMap.has(dateKey)) {
            groupedMap.set(dateKey, {
                sortDate: date,
                tickets: []
            });
        }

        groupedMap.get(dateKey)?.tickets.push(ticket);
    });

    return Array.from(groupedMap.entries())
        .sort((a, b) => a[1].sortDate.getTime() - b[1].sortDate.getTime())
        .map(([dateKey, {tickets}]) => ({
            sortData: {
                type: "date",
                value: dateKey
            },
            data: tickets.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            })
        }));
};

export const formatPrice = (price: number, currency: Currency = "USD"): string => {
    switch (currency) {
        case "USD":
            return `$${price} USD`;
        case "EUR":
            return `€${price} EUR`;
        default:
            return `${price} ${currency}`;
    }
};

/**
 * Interface for image resize parameters
 * Интерфейс для параметров изменения размера изображения
 */
interface ResizeOptions {
    /** The maximum height of the image / Максимальная высота изображения */
    height?: number;
    /** Alternative name for height / Альтернативное наименование для height */
    h?: number;
    /** The maximum width of the image / Максимальная ширина изображения */
    width?: number;
    /** Alternative name for width / Альтернативное наименование для width */
    w?: number;
    /** Crop the image to the exact height and width / Обрезать изображение до точных размеров */
    exact?: boolean;
    /** Alternative name for exact / Альтернативное наименование для exact */
    e?: boolean;
    /** Stretch the image to match the exact height and width / Растянуть изображение до точных размеров */
    stretch?: boolean;
    /** Alternative name for stretch / Альтернативное наименование для stretch */
    s?: boolean;
    /** JPEG compression value (1-100) / Качество JPEG сжатия (1-100) */
    quality?: number;
    /** Alternative name for quality / Альтернативное наименование для quality */
    q?: number;
}

/**
 * Function to add resize parameters to an image URL
 * Функция для добавления параметров изменения размера к URL изображения
 *
 * @param imageUrl - Original image URL / Исходный URL изображения
 * @param options - Object with resize parameters / Объект с параметрами изменения размера
 * @returns Image URL with parameters in format resize=w[100]h[200]... / URL изображения с параметрами в формате resize=w[100]h[200]...
 */
export function getImageUrlWithAPIParams(imageUrl: string, options: ResizeOptions = {}): string {
    // Validate input URL / Валидация входного URL
    if (!imageUrl || typeof imageUrl !== "string") {
        throw new Error(
            "A valid image URL is required / Необходимо указать корректный URL изображения"
        );
    }

    let resizeParams = "";

    // Process width parameter / Обработка параметра ширины
    const width = options.width !== undefined ? options.width : options.w;
    if (width !== undefined) {
        resizeParams += `w[${width}]`;
    }

    // Process height parameter / Обработка параметра высоты
    const height = options.height !== undefined ? options.height : options.h;
    if (height !== undefined) {
        resizeParams += `h[${height}]`;
    }

    // Process exact size parameter / Обработка параметра точного размера
    const exact = options.exact !== undefined ? options.exact : options.e;
    if (exact !== undefined) {
        resizeParams += `e[${exact}]`;
    }

    // Process stretch parameter / Обработка параметра растяжения
    const stretch = options.stretch !== undefined ? options.stretch : options.s;
    if (stretch !== undefined) {
        resizeParams += `s[${stretch}]`;
    }

    // Process quality parameter / Обработка параметра качества
    const quality = options.quality !== undefined ? options.quality : options.q;
    if (quality !== undefined) {
        // Check that quality is in the allowed range / Проверяем, что качество находится в допустимом диапазоне
        const q = Math.max(1, Math.min(100, quality));
        resizeParams += `q[${q}]`;
    }

    // If no parameters, return original URL / Если нет параметров, возвращаем исходный URL
    if (!resizeParams) {
        return imageUrl;
    }

    // Check if URL already has parameters / Проверяем, есть ли уже параметры в URL
    const hasParams = imageUrl.includes("?");

    // Form the final URL / Формируем итоговый URL
    if (hasParams) {
        return `${imageUrl}&resize=${resizeParams}`;
    } else {
        return `${imageUrl}?resize=${resizeParams}`;
    }
}
