import {DEFAULT_AIRPORT} from "../../constants";
import {groupTicketsByDate} from "../../helpers";
import {
    AircraftData,
    AirportData,
    ChipData,
    FlightAircraftData,
    FlightData,
    FlightRouteData,
    TicketData,
    TicketsSearchResult,
    UserFlightServerDataResponse,
    UserTicketData
} from "../../services/interfaces";
import {convertTimeToAmPmFormat, formatDuration} from "../../utils/Date";
import {convertUTCToLocal} from "../../utils/UTCDate";
import {getAirportByIATACode} from "./ReferenceStore";

export class FlightMapper {
    static async toFlightRouteData({
        from_airport,
        to_airport,
        arrival_time,
        departure_time,
        duration
    }: FlightData): Promise<FlightRouteData> {
        const fromAirport = await this.getAirportByIATACode(from_airport);
        const toAirport = await this.getAirportByIATACode(to_airport);

        return {
            from: fromAirport || DEFAULT_AIRPORT,
            to: toAirport || DEFAULT_AIRPORT,
            departureTime: convertUTCToLocal(departure_time, "flightRouteTime") || "00:00 AM",
            // departureTime: convertTimeToAmPmFormat(departure_time) || "00:00 AM",
            arrivalTime: convertUTCToLocal(arrival_time, "flightRouteTime") || "00:00 PM",
            // arrivalTime: convertTimeToAmPmFormat(arrival_time) || "00:00 PM",
            flightTime: formatDuration(duration) || "0m"
        };
    }

    static toAircraftData(data: FlightAircraftData): AircraftData {
        const {aircraft_type, aircraft_description, exterior_photos, interior_photos} = data;

        return {
            name: aircraft_type,
            image: exterior_photos[0] || interior_photos[0] || "", // TODO add default
            description: aircraft_description,
            exteriorPhotos: exterior_photos,
            interiorPhotos: interior_photos
        };
    }

    private static async getAirportByIATACode(iataCode: string): Promise<AirportData | undefined> {
        return getAirportByIATACode(iataCode);
    }

    static async toTicket(flightData: FlightData): Promise<TicketData> {
        const {
            id,
            seats,
            currency,
            price,
            available_from,
            aircraft_type,
            aircraft_description,
            exterior_photos,
            interior_photos
        } = flightData;

        const flightRouteData = await this.toFlightRouteData(flightData);
        const aircraftData = this.toAircraftData({
            aircraft_type,
            aircraft_description,
            exterior_photos,
            interior_photos
        });

        const date = available_from;
        const chips: ChipData[] = [];

        return {id, price, currency, chips, flightRouteData, date, aircraftData, seats};
    }

    static async toTicketsSearchResult(data: FlightData[]): Promise<TicketsSearchResult[]> {
        const ticketsData: TicketData[] = [];

        for (let i = 0; i < data.length; i++) {
            const ticketData = await FlightMapper.toTicket(data[i]);
            ticketsData.push(ticketData);
        }

        const ticketsSearchResult = groupTicketsByDate(ticketsData);
        return ticketsSearchResult;
    }

    static async toUserTicket(data: UserFlightServerDataResponse): Promise<UserTicketData> {
        const {id, status, flight} = data;
        const ticketData = await FlightMapper.toTicket(flight);
        return {id, status, ticketData};
    }

    static async toUserTickets(data: UserFlightServerDataResponse[]): Promise<UserTicketData[]> {
        const userTickets: UserTicketData[] = [];

        for (let i = 0; i < data.length; i++) {
            const userTicket = await FlightMapper.toUserTicket(data[i]);
            userTickets.push(userTicket);
        }

        return userTickets;
    }
}
