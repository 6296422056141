/* eslint-disable @typescript-eslint/ban-ts-comment */
import {observer} from "mobx-react";
import {MainButton, SecondaryButton} from "../../components/common/Buttons";
import {Header5} from "../../components/common";
import {useState} from "react";
import {useToast} from "../../customHooks/useToast";
import {useLocation, useNavigate} from "react-router-dom";
import {useRootStore} from "../../store/RootStore";
import "./BuyFlight.scss";
import {Currency} from "../../services/interfaces";
import {formatPrice} from "../../helpers";

type BuyFlightProps = {
    flightId: string;
    maxSeats?: number;
    price: number;
    currency: Currency;
};

// Todo : refactor components - the same logic is in FlightCard

export const BuyFlight = observer(({flightId, maxSeats = 5, price, currency}: BuyFlightProps) => {
    const {bookingStore} = useRootStore();
    const [ticketCount, setTicketCount] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const showToast = useToast();

    const doCount = (count: number) => {
        if (count < 1) return;
        const isMaxSeats = count > maxSeats;

        if (isMaxSeats && showToast) {
            showToast(
                "info",
                "Info",
                "Unfortunately, this flight doesnot have the required number of seats.",
                "bottom-right",
                2500
            );
        }

        if (isMaxSeats) return;
        setTicketCount(count);
    };

    const onBuyClick = () => {
        bookingStore.setLastBooking({flight_id: flightId, seats: ticketCount});
        //Todo from contstants
        navigate("/buy");
    };

    const onBackClick = () => {
        // @ts-ignore
        // Если мы на стартовой странице или нет истории
        // Проверяем есть ли предыдущая страница в истории
        if (window.history.length > 1 && location.key !== "default") {
            navigate(-1);
        } else {
            navigate("/");
        }
    };

    return (
        <div className="grid grid-nogutter justify-content-center buy-flight ">
            <div className="col-12 lg:col-8 flex justify-content-between">
                <SecondaryButton type="button" onClick={() => onBackClick()} label={"Back"} />

                <div className="price-calculator">
                    {/* See todo */}
                    <div className={"price-calculator__seats"}>
                        <div>
                            <span className={"content-s2"}>{"Seats Number"}</span>
                        </div>
                        <div>
                            <Header5>
                                <span
                                    className="count-button"
                                    onClick={() => doCount(ticketCount + 1)}
                                >
                                    +
                                </span>
                                <span className="count-value">{` ${ticketCount} Seats `}</span>
                                <span
                                    className="count-button"
                                    onClick={() => doCount(ticketCount - 1)}
                                >
                                    -
                                </span>
                            </Header5>
                        </div>
                    </div>

                    {/* See todo */}
                    <div className={"price-calculator__price"}>
                        <div>
                            <span className={"content-s2"}>{"Starting from"}</span>
                        </div>
                        <Header5>{formatPrice(price * ticketCount, currency)}</Header5>
                    </div>

                    <MainButton type="button" onClick={() => onBuyClick()} label={"Buy Ticket"} />
                </div>
            </div>
        </div>
    );
});
