import {useState} from "react";
import {Header4} from "../../components/common";

interface AircraftInfoBlockProps {
    imageUrl: string;
    title: string;
    icons?: React.ReactNode;
}

export const AircraftInfoBlock: React.FC<AircraftInfoBlockProps> = ({
    imageUrl,
    title,
    icons
}: AircraftInfoBlockProps) => {
    const [imgRatio, setImgRatio] = useState(16 / 9); // дефолтное значение

    const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const img = e.target as HTMLImageElement;
        setImgRatio(img.naturalWidth / img.naturalHeight);
    };

    return (
        <div
            className="relative w-full overflow-hidden"
            style={{
                paddingTop: `${(1 / imgRatio) * 100}%` // maintaining aspect ratio
            }}
        >
            <div className="aircraft-info absolute top-0 left-0 w-full h-full">
                <img
                    src={imageUrl}
                    alt={title}
                    className="aircraft-info-image w-full h-full object-contain"
                    onLoad={handleImageLoad}
                />

                <div className="aircraft-info-content absolute top-0 left-0 w-full h-full bg-black-alpha-40">
                    <div className="flex flex-column justify-content-between h-full p-3">
                        <div className="flex justify-content-end">{icons}</div>
                        <Header4>{title}</Header4>
                        {/* <div className="text-white text-xl font-bold"></div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
