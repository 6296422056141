import {useNavigate} from "react-router-dom";
import {Header5} from "../../components/common";
import {MainButton} from "../../components/common/Buttons";
import {observer} from "mobx-react";
import {useRootStore} from "../../store/RootStore";

export const PleaseRegister = observer(() => {
    const navigate = useNavigate();
    const {routeStore, bookingStore} = useRootStore();

    const handleLoginClick = () => {
        // Сохраняем информацию о текущей операции покупки
        if (bookingStore.lastBooking) {
            routeStore.setIntendedBooking({
                flight_id: bookingStore.lastBooking.flight_id,
                seats: bookingStore.lastBooking.seats
            });
        }
        // Устанавливаем маршрут, куда нужно вернуться после входа (/buy)
        routeStore.setIntendedRoute("/buy");
        navigate("/login");
    };

    return (
        <>
            <div className="flex flex-column justify-content-center align-items-center">
                <div>
                    <Header5>To continue booking a ticket, please login or register.</Header5>
                </div>
                <div>
                    <MainButton
                        type="button"
                        label="Login or Register"
                        onClick={handleLoginClick}
                    />
                </div>
            </div>
        </>
    );
});
