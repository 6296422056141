import {Link} from "react-router-dom";
import {
    BIRTHDAY_OPTIONAL_VALIDATION_RULE,
    EMAIL_VALIDATION_RULES,
    FIRST_NAME_VALIDATION_RULE,
    LAST_NAME_VALIDATION_RULE,
    PHONE_OPTIONAL_VALIDATION_RULE,
    ResetPasswordPageUrl,
    SuggestionsDictionary
} from "../../constants";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {DateInputControlled, TextFieldControlled} from "../fieldComponents/FieldComponents";
import {PasswordFieldControlled, PasswordSuggestionsFooter} from "../fieldComponents";
import {usePasswordSuggestions} from "../../customHooks/usePasswordSuggesions";

type FieldProps = {
    name?: string;
    control: any;
    errors?: any;
    disabled?: boolean;
};

export const FirstNameField = ({name = "firstName", control, errors, disabled}: FieldProps) => {
    const {translate} = useSafeTranslations();
    return (
        <TextFieldControlled
            rulles={{
                ...FIRST_NAME_VALIDATION_RULE
            }}
            name={name}
            control={control}
            errors={errors}
            type="text"
            placeholder={translate("first-name-text")}
            disabled={disabled}
        />
    );
};

export const LastNameField = ({name = "lastName", control, errors, disabled}: FieldProps) => {
    const {translate} = useSafeTranslations();
    return (
        <TextFieldControlled
            rulles={{
                ...LAST_NAME_VALIDATION_RULE
            }}
            name={name}
            control={control}
            errors={errors}
            type="text"
            placeholder={translate("last-name-text")}
            disabled={disabled}
        />
    );
};

export const PhoneField = ({name = "phone", control, errors, disabled}: FieldProps) => {
    const {translate} = useSafeTranslations();
    return (
        <TextFieldControlled
            rulles={{...PHONE_OPTIONAL_VALIDATION_RULE}}
            name={name}
            control={control}
            errors={errors}
            type="text"
            placeholder={translate("phone-placeholder-text")}
            disabled={disabled}
        />
    );
};

export const BirthField = ({name = "birthDay", control, errors, disabled}: FieldProps) => {
    const {translate} = useSafeTranslations();
    return (
        <DateInputControlled
            name={name}
            control={control}
            placeholder={translate("birth-date-placeholder-text")}
            errors={errors}
            readonlyInput={false}
            rulles={{
                ...BIRTHDAY_OPTIONAL_VALIDATION_RULE
            }}
            disabled={disabled}
        />
    );
};

export const LoginEmailField = ({name = "loginEmail", control, errors, disabled}: FieldProps) => {
    const {translate} = useSafeTranslations();
    return (
        <TextFieldControlled
            control={control}
            name={name}
            disabled={disabled}
            placeholder={translate("email-text")}
            type={"text"}
            rulles={{...EMAIL_VALIDATION_RULES}}
            additionalErrorComponent={
                <span>
                    <Link className={"p-informer__link"} to={ResetPasswordPageUrl}>
                        {translate("reset-password-link-text")}
                    </Link>
                </span>
            }
            errors={errors}
        />
    );
};
