import {observer} from "mobx-react";
import {UserFlightStatus, UserTicketData} from "../../services/interfaces";
import {Card} from "primereact/card";
import {classNames} from "primereact/utils";
import {AircraftInfoBlock} from "./AircraftInfoBlock";
import {Chip, ChipProps} from "primereact/chip";
import {Tooltip} from "primereact/tooltip";
import {Random} from "../../utils/Random";
import {ContentB1, Header3} from "../../components/common";
import {ReactComponent as IconAircraftFrom} from "../../icons/icon-aircraft-from.svg";
import {ReactComponent as IconAircraftTo} from "../../icons/icon-aircraft-to.svg";
import {ReactComponent as IconLongArrow} from "../../icons/icon-long-arrow.svg";
import {convertUTCToLocal} from "../../utils/UTCDate";
import {useRootStore} from "../../store/RootStore";
import {MainButton} from "../../components/common/Buttons";
import {useNavigate} from "react-router-dom";
import {convertServerUTCStringToDate} from "../../utils/utcDate/convertServerUTCStringToDate";
import {useEffect, useMemo, useState} from "react";
import {formatTimeUnits} from "../../utils/utcDate/formatTimeUnits";
import {getImageUrlWithAPIParams} from "../../helpers";

type UserTicketCardProps = {
    className?: string;
    key: string;
    data: UserTicketData;
};

type UserTicketStatusProps = {
    children?: React.ReactNode;
    className?: string;
};

type ChipFactoryProps = {
    status?: UserFlightStatus;
    payload?: {date?: string};
};

type UserTicketChipProps = ChipProps & {
    tooltip?: string;
    tooltipClassName?: string;
};

type DateCountDownChipProps = {
    dateString: string;
    className?: string;
    updateInterval?: number;
};

export const ChipFactory = ({status = undefined, payload}: ChipFactoryProps) => {
    const baseChipClassName = "user-ticket-chip";
    const baseChipTooltipClassName = "user-ticket-chip__tooltip";
    const uniqueId = Random.UUID();

    switch (status) {
        case "pending":
            return (
                <UserTicketChip
                    key={`${uniqueId}-${status}`}
                    tooltip="Your payment in pending status. Please check your email and follow instructions."
                    icon={"pi pi-clock"}
                    className={`${baseChipClassName}--${status}`}
                    tooltipClassName={`${baseChipTooltipClassName}--${status}`}
                    label={"Pending"}
                />
            );
        case "paid":
            return (
                <UserTicketChip
                    key={`${uniqueId}-${status}`}
                    tooltip="Your flight is paid for."
                    icon={"pi pi-money-bill"}
                    className={`${baseChipClassName}--${status}`}
                    tooltipClassName={`${baseChipTooltipClassName}--${status}`}
                    label={"Paid"}
                />
            );
        case "completed":
            return (
                <UserTicketChip
                    key={`${uniqueId}-${status}`}
                    tooltip="Your flight is completed."
                    icon={"pi pi-check-circle"}
                    className={`${baseChipClassName}--${status}`}
                    tooltipClassName={`${baseChipTooltipClassName}--${status}`}
                    label={"Completed"}
                />
            );
        case "cancelled":
            return (
                <UserTicketChip
                    key={`${uniqueId}-${status}`}
                    tooltip="Your flight is cancelled."
                    icon={"pi pi-times-circle"}
                    className={`${baseChipClassName}--${status}`}
                    tooltipClassName={`${baseChipTooltipClassName}--${status}`}
                    label={"Cancelled"}
                />
            );
        default:
            return null;
    }
};

export const UserTicketChip = (props: UserTicketChipProps) => {
    const {tooltip, tooltipClassName, ...restProps} = props;
    const uniqueId = Random.UUID();
    return (
        <>
            <Chip
                {...restProps}
                data-pr-tooltip={tooltip}
                data-pr-id={uniqueId}
                data-pr-position={"bottom"}
                className={classNames("user-ticket-chip", props.className)}
                key={`chip-${uniqueId}`}
            />
            {tooltip && (
                <Tooltip
                    target={`[data-pr-id="${uniqueId}"]`}
                    position={"bottom"}
                    className={classNames("user-ticket-chip__tooltip", tooltipClassName)}
                    key={`tooltip-${uniqueId}`}
                />
            )}
        </>
    );
};

export const DateCountDownChip = ({
    dateString,
    className,
    updateInterval = 60000
}: DateCountDownChipProps) => {
    const arrivalDate = convertServerUTCStringToDate(dateString);
    // Используем состояние для текущей даты, чтобы обновлять его периодически
    const [currentTime, setCurrentTime] = useState(new Date());

    // Настраиваем интервал обновления текущего времени
    useEffect(() => {
        // Создаем интервал для обновления текущего времени
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, updateInterval);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(intervalId);
    }, [updateInterval]);

    const formattedCountdown = useMemo(() => {
        // Расчет разницы времени в миллисекундах
        const timeDiffMs = arrivalDate.getTime() - currentTime.getTime();

        // Если время уже прошло, показываем 0
        if (timeDiffMs <= 0) {
            return formatTimeUnits(0, 0, 0);
        }

        // Преобразуем миллисекунды в дни, часы, минуты
        const totalMinutes = Math.floor(timeDiffMs / (1000 * 60));
        const days = Math.floor(totalMinutes / (60 * 24));
        const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
        const minutes = totalMinutes % 60;

        return formatTimeUnits(days, hours, minutes);
    }, [dateString, currentTime]);

    return (
        <Chip
            className={classNames(
                "user-ticket-chip",
                "user-ticket-chip--date-countdown",
                className
            )}
            label={formattedCountdown}
        />
    );
};

const UserTicketStatus = ({className, children}: UserTicketStatusProps) => {
    return <div className={classNames("user-ticket-card__status", className)}>{children}</div>;
};

export const UserTicketCard = observer(({className, data}: UserTicketCardProps) => {
    const {userStore} = useRootStore();
    const navigate = useNavigate();
    const onClickFillInfo = () => {
        navigate(`/account?tab=personal-info`);
    };

    const primeFlexCardClass = "h-full";
    const userTicketCardBaseClass = "user-ticket-card";

    const {id, status, ticketData} = data;
    const {date, flightRouteData} = ticketData;
    const {from, to} = flightRouteData;

    const userTicketCardStatusClass = status ? `user-ticket-card--${status}` : "";

    const showFillInfoButton = status === "pending" && !userStore.isProfileCompleted;

    return (
        <Card
            key={data.id}
            className={classNames(
                userTicketCardBaseClass,
                userTicketCardStatusClass,
                primeFlexCardClass,
                className
            )}
        >
            <UserTicketStatus key={`${id}-ticket-status`}>
                <ChipFactory key={`${id}-${status}`} status={status} payload={{date}} />
                {showFillInfoButton && <DateCountDownChip dateString={date} />}
            </UserTicketStatus>

            <Header3>{convertUTCToLocal(date, "userTicketDepartureDate")}</Header3>

            <div className="user-ticket-card-route">
                <div className="user-ticket-card-route__from">
                    <div className="direct-icon">
                        <IconAircraftFrom />
                    </div>
                    <ContentB1>{from.city}</ContentB1>
                    <div className="content-b3 airport-code ">{`(${from.iata})`}</div>
                    <div className="direct-icon">
                        <IconLongArrow />
                    </div>
                </div>
                <div className="user-ticket-card-route__to">
                    <div className="direct-icon">
                        <IconAircraftTo />
                    </div>
                    <ContentB1>{to.city}</ContentB1>
                    <div className="content-b3 airport-code">{`(${to.iata})`}</div>
                </div>
            </div>

            <AircraftInfoBlock
                imageUrl={getImageUrlWithAPIParams(data.ticketData.aircraftData.image, {
                    width: 300,
                    height: 300,
                    e: true
                })}
                title={data.ticketData.aircraftData.name}
                icons={<div></div>}
            />

            <div className="user-ticket-card-footer">
                {showFillInfoButton && (
                    <MainButton
                        className="fill-info-button"
                        label={"Fill info"}
                        onClick={onClickFillInfo}
                    />
                )}
            </div>
        </Card>
    );
});
