import {action, makeObservable, observable, when} from "mobx";
import {AirportData} from "../../services/interfaces";
import rootStore, {RootStore} from "../RootStore";
import {API} from "../../services/ApiService";

export class ReferenceStore {
    public airportsLoaded = false;
    public airportsLoading = false;
    public airportsLoadingError = false;

    public airports: AirportData[] = [];

    constructor(protected rootStore: RootStore) {
        makeObservable(this, {
            airportsLoaded: observable,
            airportsLoading: observable,
            airportsLoadingError: observable,
            loadAirports: action
        });
    }

    public async loadAirports() {
        this.airportsLoaded = false;
        this.airportsLoading = true;
        this.airportsLoadingError = false;
        try {
            const airportsResponse = await API.getAirports();
            if (airportsResponse.data && airportsResponse.errors.length === 0) {
                const {data} = airportsResponse;
                this.airports = data;
                this.airportsLoaded = true;
            }
        } catch (error) {
            this.airportsLoadingError = true;
        } finally {
            this.airportsLoading = false;
        }
    }
}

export const getAirportByIATACode = async (iataCode: string): Promise<AirportData | undefined> => {
    const referenceStore = rootStore.referenceStore;

    if (referenceStore.airportsLoaded) {
        return referenceStore.airports.find(airport => airport.iata === iataCode);
    }

    if (referenceStore.airportsLoadingError) {
        return undefined;
    }

    try {
        await when(() => referenceStore.airportsLoaded);
        return referenceStore.airports.find(airport => airport.iata === iataCode);
    } catch (error) {
        console.error("Error waiting for airports to load:", error);
        return undefined;
    }
};
