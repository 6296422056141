import {observer} from "mobx-react";
import {Badge} from "primereact/badge";
import {TabPanel, TabView} from "primereact/tabview";
import {useEffect, useMemo} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {ProfileAccountForm, ProfilePersonalInfoForm} from "../forms";
import {useRootStore} from "../../store/RootStore";

// Типы для табов
type TabItem = {
    id: string;
    label: string;
    showBadge?: boolean;
    badgeValue?: number;
    component: React.ReactNode;
};

// Конфигурация табов в отдельной переменной
const TABS_CONFIG: TabItem[] = [
    {
        id: "account",
        label: "Account",
        showBadge: false,
        badgeValue: undefined,
        component: <ProfileAccountForm />
    },
    {
        id: "personal-info",
        label: "Personal Info",
        showBadge: true,
        badgeValue: undefined,
        component: <ProfilePersonalInfoForm />
    }
];

// Дефолтный таб
const DEFAULT_TAB = "account";

type TabHeaderProps = {
    text: string;
    showBadge?: boolean;
    badgeValue?: number;
};

const TabHeader = ({text, showBadge = true, badgeValue}: TabHeaderProps) => {
    return (
        <span>
            {text} {showBadge && <Badge className="ml-2" value={badgeValue} severity="danger" />}
        </span>
    );
};

export const AccountTabs = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const {userStore} = useRootStore();

    // Получаем параметры из URL
    const queryParams = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    // Получаем текущий таб из URL или используем дефолтный
    const currentTabId = queryParams.get("tab") || DEFAULT_TAB;

    // Находим индекс текущего таба
    const activeIndex = useMemo(() => {
        const index = TABS_CONFIG.findIndex(tab => tab.id === currentTabId);
        return index !== -1 ? index : TABS_CONFIG.findIndex(tab => tab.id === DEFAULT_TAB);
    }, [currentTabId]);

    // Обработчик изменения таба
    const handleTabChange = (index: number) => {
        const newTabId = TABS_CONFIG[index]?.id || DEFAULT_TAB;
        navigate(`?tab=${newTabId}`, {replace: true});
    };

    // Устанавливаем дефолтный таб, если передан невалидный
    useEffect(() => {
        const isValidTab = TABS_CONFIG.some(tab => tab.id === currentTabId);
        if (!isValidTab && currentTabId !== DEFAULT_TAB) {
            navigate(`?tab=${DEFAULT_TAB}`, {replace: true});
        }
    }, [currentTabId, navigate]);

    const getShowBadgeValue = (tab: TabItem): boolean => {
        switch (tab.id) {
            case "personal-info":
                return !!tab.showBadge && !userStore.isProfileCompleted;
            default:
                return !!tab.showBadge;
        }
    };

    return (
        <TabView
            activeIndex={activeIndex}
            onTabChange={e => handleTabChange(e.index)}
            className={"tabs tabs--header-centered tabs--account"}
        >
            {TABS_CONFIG.map(tab => (
                <TabPanel
                    key={tab.id}
                    header={
                        <TabHeader
                            text={tab.label}
                            showBadge={getShowBadgeValue(tab)}
                            badgeValue={tab.badgeValue}
                        />
                    }
                >
                    {tab.component}
                </TabPanel>
            ))}
        </TabView>
    );
});
