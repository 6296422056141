/* eslint-disable @typescript-eslint/ban-ts-comment */
import {observer} from "mobx-react";
import {FloatLabel} from "primereact/floatlabel";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {
    AIRPORT_FROM_VALIDATION_RULE,
    AIRPORT_TO_VALIDATION_RULE,
    FLIGHT_SEARCH_CONSTANTS,
    PASSENGERS_TOTAL_VALIDATION_RULE,
    SEARCH_PAGE_PATH
} from "../../constants";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {useSearchParamsSync} from "../../customHooks/useSearchParamsSync";
import {IFlightSearchData, IFlightSearchFormData} from "../../services/interfaces";
import {useRootStore} from "../../store/RootStore";
import {formatDate, parseEuroDate} from "../../utils/Date/";
import {getDateRangeArroundDateAccordingNow} from "../../utils/utcDate/getDateRangeArroundDateAccordingNow";
import {MainButton} from "../common/Buttons";
import {
    AutocompleteAirportFieldControlled,
    DateInputControlled,
    Field,
    TextFieldControlled
} from "../fieldComponents";

type FlightSearchFormProps = {
    syncWithUrl?: boolean;
    onSuccessSearch?: () => void;
};

export const FlightSearchForm = observer(
    ({syncWithUrl = false, onSuccessSearch}: FlightSearchFormProps) => {
        const {flightSearchStore, referenceStore} = useRootStore();
        const {translate} = useSafeTranslations();
        const navigate = useNavigate();
        const location = useLocation();

        useSearchParamsSync(syncWithUrl);

        const {
            control,
            handleSubmit,
            setError,
            formState: {errors, isSubmitting},
            reset,
            watch,
            getValues
        } = useForm<IFlightSearchFormData>({
            defaultValues: {
                airportFrom: flightSearchStore.searchData.from,
                airportTo: flightSearchStore.searchData.to,
                date: flightSearchStore.searchData.date
                    ? parseEuroDate(flightSearchStore.searchData.date)
                    : null,
                passengers: flightSearchStore.searchData.passengers
            }
        });

        useEffect(() => {
            const date = flightSearchStore.searchData.date
                ? parseEuroDate(flightSearchStore.searchData.date)
                : null;

            console.log(
                "useEffect:",
                flightSearchStore.searchData.from,
                flightSearchStore.searchData.to
            );

            reset({
                //@ts-ignore
                airportFrom: flightSearchStore.searchData.from
                    ? flightSearchStore.searchData.from
                    : null,
                //@ts-ignore
                airportTo: flightSearchStore.searchData.to ? flightSearchStore.searchData.to : null,
                date: date,
                passengers: flightSearchStore.searchData.passengers
            });
        }, [flightSearchStore.searchData, flightSearchStore.isDefaultDataLoaded]);

        const onSubmit = (data: IFlightSearchFormData) => {
            const {startDate, endDate} = getDateRangeArroundDateAccordingNow(
                data.date,
                FLIGHT_SEARCH_CONSTANTS.DATE_RANGE_LEFT_OFFSET,
                FLIGHT_SEARCH_CONSTANTS.DATE_RANGE_RIGHT_OFFSET
            );

            const searchData: IFlightSearchData = {
                from: data.airportFrom,
                to: data.airportTo,
                date: data.date ? formatDate(data.date) : null,
                startDate: startDate ? formatDate(startDate) : null,
                endDate: endDate ? formatDate(endDate) : null,
                passengers: data.passengers
            };
            flightSearchStore.setSearchData(searchData);

            if (location.pathname !== SEARCH_PAGE_PATH) {
                const params = flightSearchStore.getUrlParams();
                navigate({
                    pathname: SEARCH_PAGE_PATH,
                    search: params.toString()
                });
            } else {
                flightSearchStore.search();
            }
        };

        const isLoading = flightSearchStore.isLoading || referenceStore.airportsLoading;

        return (
            <form
                className={"form form--w-full form--search-ticket"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className={"col-12 form-col "}>
                    <FloatLabel>
                        <AutocompleteAirportFieldControlled
                            name={"airportFrom"}
                            placeholder={translate("from-text")}
                            disabled={isLoading || isSubmitting}
                            control={control}
                            fieldName="city"
                            errors={errors.airportFrom}
                            rulles={{...AIRPORT_FROM_VALIDATION_RULE}}
                        />
                        <label htmlFor="airportFrom">{translate("from-text")}</label>
                    </FloatLabel>
                </div>
                <div className={"col-12 form-col "}>
                    <FloatLabel>
                        <AutocompleteAirportFieldControlled
                            name={"airportTo"}
                            placeholder={translate("to-text")}
                            disabled={isLoading || isSubmitting}
                            control={control}
                            fieldName="city"
                            errors={errors.airportTo}
                            rulles={{...AIRPORT_TO_VALIDATION_RULE}}
                        />
                        <label htmlFor="airportTo">{translate("to-text")}</label>
                    </FloatLabel>
                </div>
                <div className={"col-12 form-col "}>
                    {/* <FloatLabel>
                        <CalendarControlled
                            minDate={new Date()}
                            name={"dates"}
                            control={control}
                            disabled={isLoading || isSubmitting}
                            placeholder={translate("dates-text")}
                            selectionMode="range"
                            numberOfMonths={2}
                            readOnlyInput={true}
                            showIcon={false}
                            hideOnRangeSelection={true}
                            showButtonBar={true}
                        />
                        <label htmlFor="dates">{translate("dates-text")}</label>
                    </FloatLabel> */}

                    <FloatLabel>
                        <DateInputControlled
                            minDate={new Date()}
                            name={"date"}
                            control={control}
                            disabled={isLoading || isSubmitting}
                            placeholder={translate("dates-text")}
                            selectionMode="single"
                            numberOfMonths={1}
                            readOnlyInput={true}
                            showIcon={false}
                            hideOnRangeSelection={true}
                            showButtonBar={true}
                        />
                        <label htmlFor="date">{translate("dates-text")}</label>
                    </FloatLabel>
                </div>
                <div className={"col-12 form-col "}>
                    <FloatLabel>
                        <TextFieldControlled
                            name={"passengers"}
                            placeholder={translate("passengers-text")}
                            control={control}
                            keyfilter={"pint"}
                            disabled={isLoading || isSubmitting}
                            errors={errors.passengers}
                            rulles={{...PASSENGERS_TOTAL_VALIDATION_RULE}}
                        />
                        <label htmlFor="passengers">{translate("passengers-text")}</label>
                    </FloatLabel>
                </div>
                <div className={"col-12 form-col form-col--search-btn"}>
                    <Field>
                        <MainButton
                            className={"main-button--w-full justify-content-center"}
                            type={"submit"}
                            label={translate("search-btn-text")}
                            disabled={isLoading || isSubmitting}
                        />
                    </Field>
                </div>
            </form>
        );
    }
);
