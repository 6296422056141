import {ENV_CONST} from "../../constants";
import {handleErrorException} from "../../helpers";
import {IFlightSearchParams, IFlightSearchReponse} from "../interfaces";

const endpoint = "flights";
const {API_URL} = ENV_CONST;

export async function searchFlights(params: IFlightSearchParams): Promise<IFlightSearchReponse> {
    const endpointUrl = `${API_URL}${endpoint}`;

    const unknownError: IFlightSearchReponse = {
        data: null,
        errors: [{message: "Unknown search flights server error"}]
    };

    // TODO: Возможно переделать или инкапсулировать в отдельную функцию

    const queryParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            queryParams.append(key, value.toString());
        }
    });

    let result: IFlightSearchReponse = unknownError;

    try {
        const response = await fetch(`${endpointUrl}?${queryParams.toString()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data: IFlightSearchReponse = await response.json();
        // Hanldle server error with simple message
        if (data.errors.length > 0) {
            data.errors = [{message: "Something went wrong. Search flights server error"}];
        }
        result = data;
    } catch (error) {
        const message = handleErrorException(error);
        result.errors?.push({message});
        console.error(result.errors);
    }

    return result;
}
