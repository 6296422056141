import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {GoogleUserInfo} from "../../services/actions/googleFetchUserInfo";
import {API} from "../../services/ApiService";
import {AuthProvider, IUserData, IUserServerResponseData} from "../../services/interfaces";
import {SessionService} from "../../services/SessionService";
import {RootStore} from "../RootStore";

const defaultUserData: IUserData = {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    birthDay: null,
    personalId: null,
    allowEmailNotifications: false,
    allowSMSNotifications: false,
    allowPushNotifications: false
};

export class UserStore {
    public isLoading = false;
    public isLoggedIn = false;
    public isInitializing = true; // Указвает что происходит инициализаци при первом открытии приложения
    public accessToken?: string | null = null;
    public sessionToken: string | null = null;
    public provider: AuthProvider | null = null;

    private _userEmail: string | null = null;
    private _userId: number | null = null;

    public get userEmail() {
        return this._userEmail;
    }

    public get userId() {
        return this._userId;
    }

    public googleUserInfo: GoogleUserInfo | null = null;

    public userData: IUserData = defaultUserData;

    constructor(protected rootStore: RootStore) {
        makeObservable(this, {
            isLoading: observable,
            isLoggedIn: observable,
            isInitializing: observable,
            provider: observable,
            googleUserInfo: observable,
            userData: observable,
            isProfileCompleted: computed,
            setIsLoading: action,
            setGoogleUserInfo: action,
            setLoggedIn: action,
            logout: action,
            setUserDataByResponseData: action,
            resetUserData: action
        });
    }

    async checkAuth() {
        if (this.isLoading) return;

        this.setIsLoading(true);
        let isLoggedIn = false;
        try {
            const checkAuthData = await API.checkAuth();
            if (
                checkAuthData.isLoggedIn &&
                checkAuthData.data &&
                checkAuthData.errors.length === 0 &&
                checkAuthData.sessionToken &&
                checkAuthData.provider
            ) {
                this.setUserDataByResponseData(checkAuthData.data);
                this.setSessionToken(checkAuthData.sessionToken);
                this.setProvider(checkAuthData.provider);
                isLoggedIn = true;
            }
        } catch (error) {
            isLoggedIn = false;
            this.logout();
            throw new Error("Check auth failed");
        } finally {
            runInAction(() => {
                this.isLoading = false;
                this.isLoggedIn = isLoggedIn;
                this.isInitializing = false;
            });
        }
    }

    public setLoggedIn(loggedIn: boolean) {
        this.isLoggedIn = loggedIn;
    }

    /**
     * Set the current AuthProvider.
     * @param provider The new AuthProvider.
     */
    public setProvider(provider: AuthProvider) {
        this.provider = provider;
    }

    public setAccessToken(accessToken: string | null) {
        this.accessToken = accessToken;
    }

    public setSessionToken(sessionToken: string | null) {
        console.log("setSessionToken: ", sessionToken);
        this.sessionToken = sessionToken;
    }

    public setGoogleUserInfo(providerUserInfo: GoogleUserInfo | null) {
        this.googleUserInfo = providerUserInfo;
    }

    public setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    public setIsInitializing(isInitializing: boolean) {
        this.isInitializing = isInitializing;
    }

    public setUserDataByResponseData(data: IUserServerResponseData) {
        this._userId = data.id;
        this._userEmail = data.email;
        this.userData = {
            firstName: data.first_name,
            lastName: data.last_name,
            phoneNumber: data.phone || null,
            birthDay: data.birth_day || null,
            personalId: data.idNumber,
            allowEmailNotifications: data.allowEmailNotifications ?? false,
            allowSMSNotifications: data.allowSMSNotifications ?? false,
            allowPushNotifications: data.allowPushNotifications ?? false
        };
    }

    public resetUserData() {
        this.userData = defaultUserData;
    }

    public logout() {
        this._userId = null;
        this._userEmail = null;
        this.setIsInitializing(false);
        this.setLoggedIn(false);
        this.setIsLoading(false);
        this.setProvider(null);
        this.setAccessToken(null);
        this.setSessionToken(null);
        this.setGoogleUserInfo(null);
        this.resetUserData();
        SessionService.removeToken();
        SessionService.removeProvider();

        // TODO подумать вызывать ли тут reset для всех сторов
        this.rootStore.flightSearchStore.resetAll();
    }

    get isProfileCompleted(): boolean {
        const isTest = false;
        if (isTest) {
            return false;
        } else {
            return (
                !!this.userData.firstName && !!this.userData.lastName && !!this.userData.phoneNumber
            );
        }
    }
}
