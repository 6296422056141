/* eslint-disable max-len */
import {useGoogleLogin} from "@react-oauth/google";
import {observer} from "mobx-react";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {
    ENV_CONST,
    PASSWWORD_SUGGESTIONS_DICTIONARY,
    REQUIRED_PASSWORD_VALIDATION_RULE
} from "../../constants";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {usePasswordSuggestions} from "../../customHooks/usePasswordSuggesions";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {googleFetchUserInfo} from "../../services/actions/googleFetchUserInfo";
import {API} from "../../services/ApiService";
import {ILoginData, ILoginResponse, IUserResponse} from "../../services/interfaces";
import {SessionService} from "../../services/SessionService";
import {useRootStore} from "../../store/RootStore";
import {
    CheckboxControlled,
    Field,
    PasswordFieldControlled,
    PasswordSuggestionsFooter
} from "../fieldComponents";
import {LoginEmailField} from "../fields";
import {
    AppleLoginButton,
    ContinueLoginButton,
    FormContent,
    FormFooter,
    FormHeader,
    GoogleLoginButton,
    Header2Form,
    LoginFormSuccess
} from "../formComponents";
import {GoogleLoginComponent} from "../../pages/Login/GoogleLoginButton";

const {APPLE_LOGIN_ENABLED, GOOGLE_LOGIN_ENABLED, LOCAL_LOGIN_ENABLED} = ENV_CONST;

type LoginFormProps = {
    onSuccessLogin?: () => void;
    onErrorLogin?: () => void;
};

export const LoginForm = observer(({onSuccessLogin, onErrorLogin}: LoginFormProps) => {
    const {translate} = useSafeTranslations();

    const {userStore} = useRootStore();

    const defaultValues = {
        email: "",
        loginPassword: "",
        staySignedIn: true
    };

    const {
        control,
        handleSubmit,
        setError,
        formState: {errors, isSubmitting},
        reset,
        watch
    } = useForm<ILoginData>({
        defaultValues
    });

    const loginPasswordValue = watch("loginPassword") || "";
    const loginPasswordRulles = usePasswordSuggestions(
        loginPasswordValue,
        PASSWWORD_SUGGESTIONS_DICTIONARY
    );

    const [oauthProviderLoading, setOauthProviderLoading] = useState(false);

    //
    const googleLogin = useGoogleLogin({
        onSuccess: async response => {
            console.log("Token:", response);
            userStore.setIsLoading(true);
            setOauthProviderLoading(true);
            try {
                const userInfo = await googleFetchUserInfo(response.access_token);
                console.log("User Info:", userInfo);
                const email = userInfo.email;
                const provider = "google";
                userStore.setProvider(provider); // Set the provider
                userStore.setAccessToken(response.access_token); // Set the access token
                userStore.setGoogleUserInfo(userInfo); // Set the google user info

                const loginResponse: ILoginResponse = await API.login({
                    provider,
                    email,
                    idToken: userStore.accessToken
                });

                const session_token = loginResponse.data.session_token;
                userStore.setSessionToken(session_token); // Set the session token

                const userData: IUserResponse = await API.getUserData(userStore.sessionToken);

                if (userData.errors.length === 0 && userData.data) {
                    console.log("User:", userData);
                    userStore.setUserDataByResponseData(userData.data);
                    userStore.setLoggedIn(true);
                    SessionService.setToken(userStore.sessionToken);
                    SessionService.setProvider(userStore.provider);
                    //
                } else {
                    // Hadle error with ui;
                    console.error("Login failed", userData.errors);
                    userStore.logout();
                }
            } catch (error) {
                console.error("Login failed", error);
                userStore.logout();
                // Handle error with ui
            } finally {
                userStore.setIsLoading(false);
                userStore.setIsInitializing(false);
                setOauthProviderLoading(false);
            }
        },
        onError: error => {
            setOauthProviderLoading(false);
            console.error("Login Failed");
        }
    });
    //

    const apiCall: ApiCall<ILoginData, ILoginResponse> = data => API.login(data);

    const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<ILoginData, ILoginResponse>({
        apiCall,
        fields: ["email", "loginPassword", "staySignedIn"],
        setError,
        onSuccess: data => {
            console.log("onSuccess", data);
            reset();
        }
    });

    const onGoogleLoginClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!GOOGLE_LOGIN_ENABLED) return;
        googleLogin();
    };

    const onAppleLoginClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!APPLE_LOGIN_ENABLED) return;
    };

    const isLoading = isSubmitting || oauthProviderLoading;

    return (
        <form className="form form--login-form" onSubmit={handleSubmit(onSubmit)}>
            {isSuccessfullySubmitted ? (
                <LoginFormSuccess />
            ) : (
                <>
                    <FormHeader>
                        <Header2Form>{translate("login-form-header-text")}</Header2Form>
                    </FormHeader>
                    <FormContent className={"pt-6"}>
                        {GOOGLE_LOGIN_ENABLED && (
                            <Field>
                                {/* <GoogleLoginButton
                                    disabled={isLoading}
                                    onClick={onGoogleLoginClick}
                                /> */}
                                <GoogleLoginComponent
                                    onSuccessLogin={onSuccessLogin}
                                    onErrorLogin={onErrorLogin}
                                />
                            </Field>
                        )}
                        {APPLE_LOGIN_ENABLED && (
                            <Field>
                                <AppleLoginButton
                                    disabled={isLoading}
                                    onClick={onAppleLoginClick}
                                />
                            </Field>
                        )}
                        {(GOOGLE_LOGIN_ENABLED || APPLE_LOGIN_ENABLED) && LOCAL_LOGIN_ENABLED && (
                            <Field className={"flex justify-content-center align-items-center"}>
                                <span>{translate("or-text")}</span>
                            </Field>
                        )}

                        {LOCAL_LOGIN_ENABLED && (
                            <>
                                <LoginEmailField
                                    name={"email"}
                                    control={control}
                                    errors={errors.email}
                                    disabled={isLoading}
                                />

                                <PasswordFieldControlled
                                    name={"loginPassword"}
                                    placeholder={translate("password-text")}
                                    disabled={isLoading}
                                    control={control}
                                    rulles={REQUIRED_PASSWORD_VALIDATION_RULE}
                                    errors={errors.loginPassword}
                                    footer={
                                        <PasswordSuggestionsFooter rules={loginPasswordRulles} />
                                    }
                                />
                                <Field>
                                    <CheckboxControlled
                                        control={control}
                                        name={"staySignedIn"}
                                        disabled={isLoading}
                                        label={
                                            <label className="ml-2" htmlFor="staySignedIn">
                                                {translate("stay-signed-in-text")}
                                            </label>
                                        }
                                    />
                                </Field>
                            </>
                        )}
                    </FormContent>

                    <FormFooter>
                        {LOCAL_LOGIN_ENABLED && (
                            <Field>
                                <ContinueLoginButton disabled={isLoading} />
                            </Field>
                        )}
                    </FormFooter>
                </>
            )}
        </form>
    );
});
