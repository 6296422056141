// External imports
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {StrictMode} from "react";
import {PrimeReactProvider} from "primereact/api";
import {GoogleOAuthProvider} from "@react-oauth/google";

// Local imports
import App from "./app";
import i18n from "./lang";
// import initI18n from "./lang";
import reportWebVitals from "./util/web-vitals";

//reset css
// import "./styles/_reset.scss";

// prime react css
import "primeflex/primeflex.css";
import "primereact/resources/themes/mira/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";

// app css
import "./app/app.css";
import "./app/theme.scss";
import "./app/theme-form.scss";
import "./app/theme-tabs.scss";
import "./app/theme-toast.scss";
import "./app/theme-badge.scss";
import "./app/theme-cards.scss";
import "./app/theme-chips.scss";
import "./app/theme-pages.scss";
import "./app/theme-avatar.scss";
import {Provider} from "mobx-react";
import rootStore from "./store/RootStore";
import {DialogProvider} from "./customHooks/useDialogContext";
import {ToastProvider} from "./customHooks/useToast";
import {ENV_CONST} from "./constants";

// Global initialization
// initI18n();

const htmlRoot = document.getElementById("root") as HTMLElement;
htmlRoot.classList.add("jetsaver");
const reactRoot = ReactDOM.createRoot(htmlRoot);

// TODO: see trello comment
const {GOOGLE_LOGIN_ENABLED, GOOGLE_CLIENT_ID} = ENV_CONST;

reactRoot.render(
    process.env.REACT_APP_SCRICT_MODE === "true" ? (
        // <StrictMode>
        <Provider rootStore={rootStore}>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ""}>
                <BrowserRouter>
                    <ToastProvider>
                        <DialogProvider>
                            <PrimeReactProvider>
                                <App />
                            </PrimeReactProvider>
                        </DialogProvider>
                    </ToastProvider>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </Provider>
    ) : (
        // </StrictMode>
        <Provider rootStore={rootStore}>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ""}>
                <BrowserRouter>
                    <ToastProvider>
                        <DialogProvider>
                            <PrimeReactProvider>
                                <App />
                            </PrimeReactProvider>
                        </DialogProvider>
                    </ToastProvider>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </Provider>
    )
);

if (process.env.REACT_APP_REPORT_WEB_VITALS === "true") {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // eslint-disable-next-line no-console
    reportWebVitals(console.log);
}
