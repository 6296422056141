import {getUserLocale} from "../I18n";

export const formatTimeUnits = (
    days: number,
    hours: number,
    minutes: number,
    locale: string = getUserLocale()
): string => {
    // Словарь сокращений для разных локалей
    const timeUnitMap: Record<string, {day: string; hour: string; minute: string}> = {
        en: {day: "d", hour: "h", minute: "m"},
        "en-US": {day: "d", hour: "h", minute: "m"},
        "en-GB": {day: "d", hour: "h", minute: "m"},
        ru: {day: "д", hour: "ч", minute: "м"},
        "ru-RU": {day: "д", hour: "ч", minute: "м"},
        fr: {day: "j", hour: "h", minute: "m"},
        de: {day: "T", hour: "Std", minute: "Min"},
        es: {day: "d", hour: "h", minute: "m"},
        it: {day: "g", hour: "h", minute: "m"},
        zh: {day: "天", hour: "小时", minute: "分"},
        ja: {day: "日", hour: "時間", minute: "分"}
    };

    // Установка дефолтных значений, если локаль не найдена
    const {day, hour, minute} = timeUnitMap[locale] || timeUnitMap["en"];

    let result = "";

    if (days > 0) {
        result += `${days}${day} `;
    }

    if (hours > 0 || days > 0) {
        result += `${hours}${hour} `;
    }

    result += `${minutes}${minute}`;

    return result;
};
