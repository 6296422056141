/* eslint-disable @typescript-eslint/ban-ts-comment */
import {getUserLocale} from "./I18n";
import {convertServerUTCStringToDate} from "./utcDate/convertServerUTCStringToDate";

//! TODO IMPORTANT need refactor

type Locale = "en-US" | "ru-RU";

export type DateFormatPreset = "userTicketDepartureDate" | "flightRouteTime";

export const convertUTCToLocal = (
    utcString: string,
    preset?: DateFormatPreset,
    forceLang?: Locale
): string => {
    try {
        const date = convertServerUTCStringToDate(utcString);

        if (isNaN(date.getTime())) {
            throw new Error("Invalid date format");
        }

        const userLocale = forceLang || getUserLocale();
        const langCode = userLocale.split("-")[0];

        const enUSTimeFormatter = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        });

        const enUSDateFormatter = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "long"
        });

        const ruRUTimeFormatter = new Intl.DateTimeFormat("ru-RU", {
            hour: "numeric",
            minute: "numeric",
            hour12: false
        });

        const ruRUDateFormatter = new Intl.DateTimeFormat("ru-RU", {
            day: "numeric",
            month: "long"
        });

        switch (preset) {
            case "userTicketDepartureDate":
                switch (langCode) {
                    case "en":
                        return `${enUSTimeFormatter.format(date)}, ${enUSDateFormatter.format(
                            date
                        )}`;
                    case "ru":
                        return `${ruRUTimeFormatter.format(date)}, ${ruRUDateFormatter.format(
                            date
                        )}`;
                    default:
                        return `${enUSTimeFormatter.format(date)}, ${enUSDateFormatter.format(
                            date
                        )}`;
                }
            case "flightRouteTime": {
                switch (langCode) {
                    case "en":
                        return enUSTimeFormatter.format(date);
                    case "ru":
                        return ruRUTimeFormatter.format(date);

                    default:
                        return enUSTimeFormatter.format(date);
                }
            }
            default:
                return date.toLocaleString(userLocale);
        }
    } catch (error) {
        console.error("Error converting date:", error);
        return utcString;
    }
};
