import {classNames} from "primereact/utils";
import {ContentB1, Header5} from "../common";
import {Chip} from "primereact/chip";
import {ReactComponent as IconAircraftFrom} from "../../icons/icon-aircraft-from.svg";
import {ReactComponent as IconAircraftTo} from "../../icons/icon-aircraft-to.svg";
import {ReactComponent as IconRoute} from "../../icons/icon-ticket-card-route.svg";
import {ReactComponent as IconRouteSmall} from "../../icons/icon-ticket-card-route-small.svg";
import {AircraftData, ChipData, FlightRouteData} from "../../services/interfaces";
import {FlightCardThumbnail} from "./FlightCardThumbnail";
import {getImageUrlWithAPIParams} from "../../helpers";

const DefaultChipData: ChipData[] = [
    {type: "views", label: "99"},
    {type: "info", label: "LAST SEAT"}
];

interface ITicketCardComponentProps {
    className?: string;
}

interface ITicketCardFlightDetailsProps extends ITicketCardComponentProps {
    aircraftData: AircraftData;
    chips?: React.ReactNode;
}

interface ITicketCardPriceProps extends ITicketCardComponentProps {
    formatedPrice?: string;
}

interface ITicketCardRouteDetailsProps extends ITicketCardComponentProps {
    flightRouteData: FlightRouteData;
}

interface ITicketCardChipsProps extends ITicketCardComponentProps {
    chipData?: ChipData[];
}

export const TicketCardFlightDetails = ({
    className,
    aircraftData,
    chips
}: ITicketCardFlightDetailsProps) => {
    return (
        <div className={classNames("ticket-card__flight-details", className)}>
            <FlightCardThumbnail
                src={getImageUrlWithAPIParams(aircraftData.image, {
                    width: 200,
                    height: 200,
                    exact: true
                })}
                alt={aircraftData.name}
            />
            <div className={"flex flex-column ml-2"}>
                <ContentB1>{aircraftData.name}</ContentB1>
                <div className={"flex gap-1"}>{chips}</div>
            </div>
        </div>
    );
};

export const TicketCardPrice = ({
    className,
    formatedPrice: price = "$ 152.250 USD"
}: ITicketCardPriceProps) => {
    return (
        <div className={classNames("ticket-card__price", className)}>
            <div>
                <span className={"content-s2"}>{"Starting from"}</span>
            </div>
            <Header5>{price}</Header5>
        </div>
    );
};

export const TicketCardFlightRouteDetails = ({
    className,
    flightRouteData
}: ITicketCardRouteDetailsProps) => {
    return (
        <div className={classNames("ticket-card__flight-route-details", className)}>
            <div className={"ticket-card__flight-route-from justify-content-between"}>
                <div className={"flex justify-content-between align-items-center gap-2"}>
                    <IconAircraftFrom />
                    <span className={"content-s1"}>{flightRouteData.from.city}</span>
                    <span className={"content-s2"}>{`(${flightRouteData.from.iata})`}</span>
                </div>
                <Header5>{flightRouteData.departureTime}</Header5>
            </div>
            <div
                className={
                    "ticket-card__flight-time justify-content-start align-items-center gap-1"
                }
            >
                <div className={"flex justify-content-center"}>
                    <IconRouteSmall className={"sm:hidden"} />
                    <IconRoute className={"hidden sm:block"} />
                </div>
                <div>
                    <span className={"content-s3"}>{"Flight time: "}</span>
                    <span className={"content-s1"}>{flightRouteData.flightTime}</span>
                </div>
            </div>
            <div className={"ticket-card__flight-route-to justify-content-between "}>
                <div className={"flex justify-content-between align-items-center gap-2"}>
                    <IconAircraftTo />
                    <span className={"content-s1"}>{flightRouteData.to.city}</span>
                    <span className={"content-s2"}>{`(${flightRouteData.to.iata})`}</span>
                </div>
                <Header5>{flightRouteData.arrivalTime}</Header5>
            </div>
        </div>
    );
};

export const TicketCardChips = ({chipData = DefaultChipData}: ITicketCardChipsProps) => {
    const baseClassName = "chip--ticket-card";
    return chipData ? (
        <>
            {chipData.map((chip, index) => {
                return (
                    <Chip
                        key={`chip-${index}`}
                        className={classNames("chip", `${baseClassName}-${chip.type}`)}
                        label={chip.label}
                    />
                );
            })}
        </>
    ) : null;
};
