import {Card} from "primereact/card";
import {MainButton} from "../common/Buttons";
import {
    TicketCardChips,
    TicketCardFlightDetails,
    TicketCardFlightRouteDetails,
    TicketCardPrice
} from "./CardComponents";
import {TicketCardLikeButton} from "./FlightCardLikeButton";
import {observer} from "mobx-react";
import {TicketData} from "../../services/interfaces";
import {useNavigate} from "react-router-dom";
import {formatPrice} from "../../helpers";

type TicketCardProps = {
    className?: string;
    key: string;
    data: TicketData;
};

export const TicketCard = observer(({className, data}: TicketCardProps) => {
    const navigate = useNavigate();

    const showLikeButton = false;

    const mainButtonText = "Select Ticket";

    const {id} = data;

    const onClickHandler = () => {
        console.log(id);
        navigate(`/flights/${id}`);
    };

    return (
        <Card className={"ticket-card"}>
            <div className={"grid grid-nogutter justify-content-between align-items-center w-full"}>
                <TicketCardFlightDetails
                    className={"col-10 sm:col-8 md:col-4 lg:col-3 xl:col-3 flex-order-0"}
                    chips={<TicketCardChips chipData={data.chips} />}
                    aircraftData={data.aircraftData}
                />

                <TicketCardFlightRouteDetails
                    flightRouteData={data.flightRouteData}
                    className={
                        "col-12 md:col-7 lg:col-6 xl:col-5 flex-order-2 md:flex-order-1 gap-1"
                    }
                />

                <TicketCardPrice
                    formatedPrice={formatPrice(data.price, data.currency)}
                    className={"flex-order-3 lg:col-2 xl:col-2 lg:flex-order-2"}
                />

                {showLikeButton && (
                    <div
                        className={
                            "/*col-2 md:col-1*/ flex justify-content-center align-items-center flex-order-1 md:flex-order-2 lg:flex-order-3"
                        }
                    >
                        <TicketCardLikeButton />
                    </div>
                )}

                <div className={"flex-order-4"}>
                    <MainButton type={"button"} onClick={onClickHandler} label={mainButtonText} />
                </div>
            </div>
        </Card>
    );
});
