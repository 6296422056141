import {Header2, Header4} from "../../components/common";
import "./EmptySearchResult.scss";

type ResourceType = "flights" | "flight" | "userFlights";

type EmptySearchResultProps = {type?: ResourceType};

export const EmptyFlightsResult = ({type = "flights"}: EmptySearchResultProps) => {
    let title = "No flight found.";
    let message = "It seems like we have no appropriate flights for your request";

    switch (type) {
        case "flights":
            title = "No flight found.";
            message = "It seems like we have no appropriate flight for your request";
            break;
        case "flight":
            title = "Flight not found";
            message = "Flight data not found";
            break;
        case "userFlights":
            title = "No flight found.";
            message = "It seems like you have no flights";
            break;
    }

    return (
        <div className="empty-flights-result">
            <Header2>{title} </Header2>
            <Header4>{message}</Header4>
        </div>
    );
};
