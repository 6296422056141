import {ENV_CONST} from "../../constants";
import {handleErrorException} from "../../helpers";
import {createHttpClient} from "../createHttpClient";
import {IUserFlightResponse} from "../interfaces/flight";

const endpoint = "flights/bookings";
const {API_URL} = ENV_CONST;

const getUnexpectedErrorUserFlightResponse = (): IUserFlightResponse => ({
    data: null,
    errors: [{message: "Unexpected user flights response error"}]
});

const httpClient = createHttpClient(API_URL);

export async function getUserFlights(session_token?: string | null): Promise<IUserFlightResponse> {
    let result: IUserFlightResponse = getUnexpectedErrorUserFlightResponse();

    try {
        result = await httpClient.get<IUserFlightResponse>(endpoint, session_token);
    } catch (e) {
        const message = handleErrorException(e);
        result.errors?.push({message});
        console.error(result.errors);
    }

    return result;

    /*
    if (TEST_MODE) {
      const errorMockData: IUserFlightResponse = {
        data: null,
        errors: [{message: "Error something went wrong "}]
    };

    const emptyMockData: IUserFlightResponse = {
        data: [],
        errors: []
    };

    const successMockData: IUserFlightResponse = {
        data: [
            {
                id: "1",
                status: "pending",
                flight: {
                    id: "43367940",
                    aircraft_category: "Midsize",
                    aircraft_type: "Citation XLS",
                    aircraft_super_type: "",
                    price: 4263,
                    currency: "USD",
                    seller_company: "PANELLENIC PRIVATE AVIATION",
                    from_airport: "LFPB",
                    to_airport: "LYBE",
                    available_from: "2025-02-02 19:30:00",
                    exterior_photos: [
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_panellenic.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/WhatsApp_Image_2022_04_30_at_11.06.16_AM_1.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_int.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_1.jpeg"
                    ],
                    interior_photos: [
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/XL_Interior_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/XLS_Interior.png",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/xlsplus_interior_15_961_167_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/citation_xls_exterior.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/xlsplus_exterior_15_963_2166_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/citation_xls_exterior.jpg"
                    ],
                    duration: 120,
                    departure_time: "2025-02-02 18:30:00",
                    arrival_time: "2025-02-02 20:30:00",
                    seats: 8,
                    aircraft_description:
                        "Cessna's upgrade to the extremely popular Citation Excel private jet, the XLS offers unique performance figures together with extraordinary cabin comfort. The perfect combination of range, speed, payload and high altitude airport capabilities is remarkable for an eight seat business jet."
                }
            },
            {
                id: "2",
                status: "paid",
                flight: {
                    id: "43367919",
                    aircraft_category: "Light",
                    aircraft_type: "Cessna Citation CJ3+",
                    aircraft_super_type: "",
                    price: 4263,
                    currency: "USD",
                    seller_company: "Hahn Air Lines GmbH",
                    from_airport: "EDDL",
                    to_airport: "LIPK",
                    available_from: "2025-02-17 16:15:00",
                    exterior_photos: [
                        "https://secure.returnjet.com/uploads/jets/2020/12/CJ3_Exterieur_runway_D_CHRF.jpg",
                        "https://secure.returnjet.com/uploads/jets/2020/12/CJ3_Interieur_club_seating_D_CHRF.jpg",
                        "https://secure.returnjet.com/uploads/jets/2020/12/CJ3_Exterieur_back_D_CHRF.jpg",
                        "https://secure.returnjet.com/uploads/jets/2020/12/CJ3_Exterieur_front_D_CHRF.jpg"
                    ],
                    interior_photos: [
                        "https://secure.returnjet.com/uploads/jets_models/2016/03/CJ3_.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2016/03/citationcj3plusalpine1928_cessna.jpg"
                    ],
                    duration: 105,
                    departure_time: "2025-02-17 15:15:00",
                    arrival_time: "2025-02-17 17:00:00",
                    seats: 6,
                    aircraft_description:
                        "The Citation Jet 3+ is a small-cabined executive jet with impressive legroom, ensuring a comfortable flight. Typically configured with six individual passenger seats, the aircraft features a refreshment centre, an emergency washroom and small external luggage hold. These private jets have excellent short field performance and with a 1,900 nautical mile range make very versatile aircraft."
                }
            },
            {
                id: "3",
                status: "completed",
                flight: {
                    id: "43367940",
                    aircraft_category: "Midsize",
                    aircraft_type: "Citation XLS",
                    aircraft_super_type: "",
                    price: 4263,
                    currency: "USD",
                    seller_company: "PANELLENIC PRIVATE AVIATION",
                    from_airport: "LFPB",
                    to_airport: "LYBE",
                    available_from: "2025-02-02 19:30:00",
                    exterior_photos: [
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_panellenic.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/WhatsApp_Image_2022_04_30_at_11.06.16_AM_1.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_int.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_1.jpeg"
                    ],
                    interior_photos: [
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/XL_Interior_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/XLS_Interior.png",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/xlsplus_interior_15_961_167_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/citation_xls_exterior.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/xlsplus_exterior_15_963_2166_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/citation_xls_exterior.jpg"
                    ],
                    duration: 120,
                    departure_time: "2025-02-02 18:30:00",
                    arrival_time: "2025-02-02 20:30:00",
                    seats: 8,
                    aircraft_description:
                        "Cessna's upgrade to the extremely popular Citation Excel private jet, the XLS offers unique performance figures together with extraordinary cabin comfort. The perfect combination of range, speed, payload and high altitude airport capabilities is remarkable for an eight seat business jet."
                }
            },
            {
                id: "4",
                status: "cancelled",
                flight: {
                    id: "43367940",
                    aircraft_category: "Midsize",
                    aircraft_type: "Citation XLS",
                    aircraft_super_type: "",
                    price: 4263,
                    currency: "USD",
                    seller_company: "PANELLENIC PRIVATE AVIATION",
                    from_airport: "LFPB",
                    to_airport: "LYBE",
                    available_from: "2025-02-02 19:30:00",
                    exterior_photos: [
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_panellenic.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/WhatsApp_Image_2022_04_30_at_11.06.16_AM_1.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_int.jpeg",
                        "https://secure.returnjet.com/uploads/jets/2023/06/XLS_1.jpeg"
                    ],
                    interior_photos: [
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/XL_Interior_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/XLS_Interior.png",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/xlsplus_interior_15_961_167_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2016/06/citation_xls_exterior.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/xlsplus_exterior_15_963_2166_1.jpg",
                        "https://secure.returnjet.com/uploads/jets_models/2015/10/citation_xls_exterior.jpg"
                    ],
                    duration: 120,
                    departure_time: "2025-02-02 18:30:00",
                    arrival_time: "2025-02-02 20:30:00",
                    seats: 8,
                    aircraft_description:
                        "Cessna's upgrade to the extremely popular Citation Excel private jet, the XLS offers unique performance figures together with extraordinary cabin comfort. The perfect combination of range, speed, payload and high altitude airport capabilities is remarkable for an eight seat business jet."
                }
            }
        ],
        errors: []
    };

    if (Random.bool(0.5)) {
        return successMockData;
    } else {
        if (Random.bool(0.5)) {
            return emptyMockData;
        } else {
            return errorMockData;
        }
    }
    } */
}
