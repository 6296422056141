import {Galleria} from "primereact/galleria";
import {useState, useRef} from "react";
import "./FlightGallery.scss";
import {getImageUrlWithAPIParams} from "../../helpers";

interface ImageGalleryProps {
    images: string[];
    className?: string;
}

export const FlightGallery = ({images, className}: ImageGalleryProps) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const itemTemplate = (item: string) => {
        const src = getImageUrlWithAPIParams(item, {width: 1000, height: 600, e: true});
        return <img src={src} alt="gallery" />;
    };

    const thumbnailTemplate = (item: string) => {
        const src = getImageUrlWithAPIParams(item, {width: 200, height: 200, e: true});
        return <img src={src} alt="thumbnail" />;
    };

    return (
        <Galleria
            value={images}
            activeIndex={activeIndex}
            onItemChange={e => setActiveIndex(e.index)}
            circular
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
            className={className}
            showItemNavigators
            thumbnailsPosition="bottom"
            numVisible={images.length < 5 ? images.length : 5}
        />
    );
};
