// Функция для получения диапазона дат
export const getDateRangeArroundDateAccordingNow = (
    centralDate: Date | undefined | null,
    offsetLeftDay = 7,
    offsetRightDay = 7
): {startDate: Date; endDate: Date} => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const normalizedCentralDate = centralDate ? new Date(centralDate) : new Date(today);
    normalizedCentralDate.setHours(0, 0, 0, 0);

    // Если centralDate раньше текущей даты, используем текущую дату вместо него
    const effectiveCentralDate =
        normalizedCentralDate < today ? new Date(today) : normalizedCentralDate;

    const preliminaryStartDate = new Date(effectiveCentralDate);
    preliminaryStartDate.setDate(effectiveCentralDate.getDate() - offsetLeftDay);

    let startDate;
    let endDate;

    if (preliminaryStartDate >= today) {
        // Полный диапазон
        startDate = new Date(preliminaryStartDate);
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + offsetLeftDay + offsetRightDay);
    } else {
        // Усеченный диапазон (startDate = текущая дата)
        startDate = new Date(today);

        // Вычисляем разницу в днях между centralDate и текущей датой
        const diffTime = effectiveCentralDate.getTime() - today.getTime();
        const daysDifference = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + offsetRightDay + daysDifference);
    }

    return {
        startDate,
        endDate
    };
};

// TODO - remove this
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
window.getDateRangeArroundDateAccordingNow = getDateRangeArroundDateAccordingNow;
