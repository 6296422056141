export const getCentralDateFromDateRange = (
    startDate: Date,
    endDate: Date,
    offsetLeftDay = 7,
    offsetRightDay = 7
): Date => {
    // Нормализуем входные даты
    const normalizedStartDate = new Date(startDate);
    normalizedStartDate.setHours(0, 0, 0, 0);

    const normalizedEndDate = new Date(endDate);
    normalizedEndDate.setHours(0, 0, 0, 0);

    // Вычисляем разницу в днях
    const diffTime = normalizedEndDate.getTime() - normalizedStartDate.getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    if (diffDays === offsetLeftDay + offsetRightDay) {
        // Случай 1: Полный диапазон
        const centralDate = new Date(normalizedStartDate);
        centralDate.setDate(normalizedStartDate.getDate() + offsetLeftDay);
        return centralDate;
    } else {
        // Случай 2: Усеченный диапазон
        const centralDate = new Date(normalizedStartDate);
        centralDate.setDate(normalizedStartDate.getDate() + (diffDays - offsetRightDay));
        return centralDate;
    }
};
