import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {useEffect, useState} from "react";
import {API} from "../../services/ApiService";
import {APIError, CommonError, UserTicketData} from "../../services/interfaces";
import {FlightMapper} from "../../store/substores/Flight.mapper";
import {PageAuthPreloader} from "../../components/LoadingComponents/PageAuthPreloader";
import {ErrorBlock} from "./ErrorBlock";
import {useToast} from "../../customHooks/useToast";
import useToastError from "../../customHooks/useToastError";
import {handleErrorException} from "../../helpers";
import {EmptyFlightsResult} from "../Search/EmptySearchResult";
import "./MyFlights.scss";
import {UserTicketCard} from "./UserTicketCard";
import {useRootStore} from "../../store/RootStore";

const pageId = PagesId.MyFlights;

export const MyFlights = observer(() => {
    const {userStore} = useRootStore();
    const showToast = useToast();

    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState<(CommonError | APIError)[]>([]);
    const [userFlights, setUserFlights] = useState<UserTicketData[]>([]);

    useEffect(() => {
        const getUserFlights = async () => {
            try {
                const result = await API.getUserFlights(userStore.sessionToken);

                if (result.errors.length > 0) {
                    setIsError(true);
                    setErrors(result.errors);
                } else {
                    if (result.data) {
                        const userFlights = await FlightMapper.toUserTickets(result.data);
                        setUserFlights(userFlights);
                        setIsError(false);
                    }
                }
                setLoading(false);
            } catch (error) {
                setIsError(true);
                const message = handleErrorException(error);
                setErrors([message]);
            } finally {
                setLoading(false);
            }
        };

        getUserFlights();
    }, []);

    useEffect(() => {
        if (isError && errors.length > 0) {
            useToastError(showToast, errors);
        }
    }, [isError, errors]);

    return (
        <PageContent className={"page-my-flights"} pageId={pageId}>
            {loading ? (
                <PageAuthPreloader />
            ) : isError ? (
                <ErrorBlock />
            ) : userFlights.length > 0 ? (
                <div className="grid user-ticket-collection">
                    {userFlights.map((userFlight: UserTicketData) => (
                        <div
                            key={`${userFlight.id}-wrapper`}
                            className="col-12 md:col-6 lg:col-4 xl:col-3"
                        >
                            <UserTicketCard
                                key={`user-ticket-card-${userFlight.id}`}
                                data={userFlight}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <EmptyFlightsResult type={"userFlights"} />
            )}
        </PageContent>
    );
});

export default MyFlights;
