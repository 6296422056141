import {ENV_CONST} from "../../constants";
import {handleErrorException} from "../../helpers";
import {createHttpClient} from "../createHttpClient";
import {IBookData, IBookResponse} from "../interfaces";

const endpoint = "flights/booking";

const {API_URL} = ENV_CONST;
const endpointUrl = `${API_URL}${endpoint}`;

const httpClient = createHttpClient(API_URL);

export async function bookTicket(
    bookData: IBookData,
    token?: string | null
): Promise<IBookResponse> {
    if (!token) {
        return {
            data: null,
            errors: [{message: "No token provided"}]
        };
    }

    const unknownError: IBookResponse = {
        data: null,
        errors: [{message: "Unknown book ticket server error"}]
    };

    const {flight_id, seats} = bookData;

    let result: IBookResponse = unknownError;

    try {
        const response = await httpClient.post<IBookResponse>(endpoint, bookData, token);
        result = response;
    } catch (error) {
        const message = handleErrorException(error);
        result.errors?.push({message});
        console.error(result.errors);
    }

    return result;
}
