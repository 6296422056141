import {ENV_CONST} from "../constants";
import {WaitAction} from "../utils/WaitAction";
import {bookTicket} from "./actions/bookTicket";
import {checkAuth} from "./actions/checkAuth";
import {getAirports} from "./actions/getAirports";
import {getFlight} from "./actions/getFlight";
import {login} from "./actions/login";
import {logout} from "./actions/logout";
import {searchFlights} from "./actions/searchFlights";
import {getUserData, updateUserData} from "./actions/user";
import {getUserFlights} from "./actions/userFlights";
import {validateResetToken} from "./actions/validateResetToken";
import {
    INewPasswordResponse,
    IProfilePasswordData,
    IProfilePersonalID,
    IProfilePersonalInfoData,
    IProfilePersonalInfoResponse,
    IResetPasswordResponse,
    IServerAPI,
    ISetGeneralUserInfoResponse,
    IUserGeneralInfo
} from "./interfaces/";

const {IS_DEV_FRONTEND_MODE} = ENV_CONST;

async function resetPassword(resetEmail: string) {
    const res: IResetPasswordResponse = {
        data: {},
        errors: []
    };
    return res;
}

async function setNewPass(oldPass: string, newPass: string) {
    const res: INewPasswordResponse = {
        data: {},
        errors: []
    };
    return res;
}

async function setTestNewPass(oldPass: string, newPass: string) {
    return WaitAction.sec(3).then(() => {
        const res: INewPasswordResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

async function testCheckAuth() {
    return WaitAction.sec(0.5).then(() => false);
}

async function testResetPassword(resetEmail: string) {
    const isUnkownError = false;
    const isSuccess = true;
    const status = isSuccess ? "success" : "error";
    const message = isSuccess ? "Email reset" : "Email invalid";

    if (isUnkownError) {
        throw new Error("Unknown error on reset password");
    }

    return WaitAction.sec(2).then(() => {
        const res: IResetPasswordResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

async function setUserGeneralInfo(params: IUserGeneralInfo) {
    return WaitAction.sec(3).then(() => {
        const res: ISetGeneralUserInfoResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

async function setTestUserGeneralInfo(params: IUserGeneralInfo) {
    return WaitAction.sec(3).then(() => {
        const res: ISetGeneralUserInfoResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

async function changePass(data: IProfilePasswordData) {
    return WaitAction.sec(3).then(() => {
        const res: INewPasswordResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

async function testChangePass(data: IProfilePasswordData) {
    return WaitAction.sec(3).then(() => {
        const res: INewPasswordResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

async function setPersonalInfo(params: IProfilePersonalInfoData) {
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

async function testSetPersonalInfo(params: IProfilePersonalInfoData) {
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

export function setPersonalID(params: IProfilePersonalID) {
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

export function testSetPersonalID(params: IProfilePersonalID) {
    console.log("testSetPersonalID", params);
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            data: {},
            errors: []
        };
        return res;
    });
}

export const API: IServerAPI = {
    checkAuth: checkAuth,
    login: login,
    logout: logout,
    getUserData: getUserData,
    updateUserData: updateUserData,
    validateResetToken: validateResetToken,
    getFlight: getFlight,
    getAirports: getAirports,
    searchFlights: searchFlights,
    bookTicket: bookTicket,
    getUserFlights: getUserFlights,
    resetPassword: IS_DEV_FRONTEND_MODE ? testResetPassword : resetPassword,
    setNewPass: IS_DEV_FRONTEND_MODE ? setTestNewPass : setNewPass,
    //TODO: remove this
    setUserGeneralInfo: IS_DEV_FRONTEND_MODE ? setTestUserGeneralInfo : setUserGeneralInfo,
    changePass: IS_DEV_FRONTEND_MODE ? testChangePass : changePass,
    //TODO: remove this
    setPersonalInfo: IS_DEV_FRONTEND_MODE ? testSetPersonalInfo : setPersonalInfo,
    setPersonalID: IS_DEV_FRONTEND_MODE ? testSetPersonalID : setPersonalID
};
