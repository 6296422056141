import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {useRootStore} from "../../store/RootStore";
import {PleaseRegister} from "./PleaseRegister";
import {useEffect, useState} from "react";
import {PageAuthPreloader} from "../../components/LoadingComponents/PageAuthPreloader";
import {ContentB1} from "../../components/common";
import {API} from "../../services/ApiService";
import {LandingLoginFormSuccess} from "../../components/formComponents";
import {ErrorBlock} from "./ErrorBlock";
import {useNavigate} from "react-router-dom";

const pageId = PagesId.Buy;

const BuyPage = observer(() => {
    const {userStore, bookingStore, routeStore} = useRootStore();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        // Если пользователь авторизован, но нет данных о бронировании...
        if (userStore.isLoggedIn && !bookingStore.lastBooking) {
            // Проверяем, не осталось ли данных в routeStore
            if (routeStore.intendedBooking) {
                // Восстанавливаем данные бронирования
                bookingStore.setLastBooking({
                    flight_id: routeStore.intendedBooking.flight_id,
                    seats: routeStore.intendedBooking.seats
                });
                routeStore.setIntendedBooking(null);
            } else {
                // Если нет данных о бронировании, отправляем на главную
                navigate("/");
                return;
            }
        }

        if (userStore.isLoggedIn && bookingStore.lastBooking) {
            const book = async () => {
                setLoading(true);
                try {
                    const result = await API.bookTicket(
                        {
                            flight_id: bookingStore.lastBooking?.flight_id,
                            seats: bookingStore.lastBooking?.seats
                        },
                        userStore.sessionToken
                    );

                    if (result.errors.length > 0) {
                        setIsError(true);
                    }
                } catch (error) {
                    setIsError(true);
                } finally {
                    setLoading(false);
                }
            };

            book();
        } else {
            // Для незалогиненных пользователей просто выключаем прелоадер
            setLoading(false);
        }

        return () => {
            // Не очищаем bookingStore.lastBooking здесь, если пользователь не залогинен
            // Это позволит сохранить данные при авторизации через PleaseRegister
            if (userStore.isLoggedIn) {
                bookingStore.setLastBooking(null);
            }
        };
    }, [userStore.isLoggedIn]); // Добавляем зависимость, чтобы useEffect срабатывал после логина

    return (
        <PageContent pageId={pageId}>
            {userStore.isLoggedIn ? (
                loading ? (
                    <PageAuthPreloader />
                ) : isError ? (
                    <ErrorBlock />
                ) : (
                    <LandingLoginFormSuccess />
                )
            ) : (
                <PleaseRegister />
            )}
        </PageContent>
    );
});

export default BuyPage;
