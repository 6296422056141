import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {useDialogContext} from "../../customHooks/useDialogContext";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {useToast} from "../../customHooks/useToast";
import {API} from "../../services/ApiService";
import {APIError, IUserData, IUserResponse} from "../../services/interfaces";
import {useRootStore} from "../../store/RootStore";
import {Header4} from "../common";
import {SecondaryButton} from "../common/Buttons";
import {RegisterFormPersonalIDDialog} from "../dialogs";
import {CheckboxControlled, Field, TextField} from "../fieldComponents";
import {BirthField, FirstNameField, LastNameField, PhoneField} from "../fields";
import {AccountFormFooter, FormContent, FormHeader} from "../formComponents";
import {useEffect, useMemo} from "react";
import useToastError from "../../customHooks/useToastError";

// temporary
const SHOW_PERSONAL_ID_VERIFICATION = false;
const SHOW_NOTIFICATIONS_OPTIONS = false;
const SHOW_BIRTH_DAY = false;

export const ProfilePersonalInfoForm = observer(() => {
    const {userStore} = useRootStore();
    const {translate} = useSafeTranslations();
    const {openDialog, closeDialog} = useDialogContext();
    const showToast = useToast();

    const defaultValues = useMemo(
        () => ({
            allowEmailNotifications: userStore.userData.allowEmailNotifications,
            allowSMSNotifications: userStore.userData.allowSMSNotifications,
            allowPushNotifications: userStore.userData.allowPushNotifications,
            firstName: userStore.userData.firstName || "",
            lastName: userStore.userData.lastName || "",
            birthDay: userStore.userData.birthDay || "",
            phoneNumber: userStore.userData.phoneNumber || ""
        }),
        [userStore.userData]
    );

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: {errors, isSubmitting, isDirty}
    } = useForm<IUserData>({
        defaultValues,
        mode: "onChange"
    });

    const apiCall: ApiCall<IUserData, IUserResponse> = data =>
        API.updateUserData(data, userStore.sessionToken);

    const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<IUserData, IUserResponse>({
        apiCall,
        fields: [
            "phoneNumber",
            "firstName",
            "lastName",
            "birthDay",
            "allowEmailNotifications",
            "allowSMSNotifications",
            "allowPushNotifications"
        ],
        setError,
        onError: (errors: APIError[]) => {
            // Todo see hook useToastError
            if (errors && showToast) {
                errors.forEach(error => {
                    if (typeof error === "string") {
                        showToast("error", "Error", error, "top-center", 3000);
                    } else {
                        showToast("error", "Error", error.message, "top-center", 3000);
                    }
                });
            }
        },
        onSuccess: data => {
            console.log("onSuccess", data);
            if (data.data) {
                userStore.setUserDataByResponseData(data.data);
                showToast &&
                    showToast(
                        "success",
                        "Success",
                        "Profile updated successfully",
                        "bottom-right",
                        3000
                    );
            }
        }
    });

    const onPathVerificationClick = () => {
        openDialog(RegisterFormPersonalIDDialog, {
            onCloseDialog: closeDialog
        });
    };

    return (
        <form
            className="form form--wide form--tab-adapted form--profile-personal-info"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormHeader></FormHeader>
            <FormContent>
                <div className="grid">
                    <div className="col-6 p-5 pb-0 pt-0">
                        <Header4>{translate("general-information-text")}</Header4>
                        <FirstNameField
                            name={"firstName"}
                            control={control}
                            errors={errors.firstName}
                            disabled={isSubmitting}
                        />
                        <LastNameField
                            name={"lastName"}
                            control={control}
                            errors={errors.lastName}
                            disabled={isSubmitting}
                        />

                        <PhoneField
                            name={"phoneNumber"}
                            control={control}
                            errors={errors.phoneNumber}
                            disabled={isSubmitting}
                        />

                        {SHOW_BIRTH_DAY && (
                            <BirthField
                                name={"birthDay"}
                                control={control}
                                errors={errors.birthDay}
                                disabled={isSubmitting}
                            />
                        )}

                        {/*  */}
                        {SHOW_NOTIFICATIONS_OPTIONS && (
                            <>
                                <Header4>{translate("notifacation-text")}</Header4>
                                <CheckboxControlled
                                    name="allowSMSNotifications"
                                    control={control}
                                    disabled={isSubmitting}
                                    label={
                                        <label
                                            className="checkbox-label ml-2"
                                            htmlFor="allowSMSNotifications"
                                        >
                                            {"I allow notification via SMS"}
                                        </label>
                                    }
                                />
                                <CheckboxControlled
                                    name="allowEmailNotifications"
                                    control={control}
                                    disabled={isSubmitting}
                                    label={
                                        <label
                                            className="checkbox-label ml-2"
                                            htmlFor="allowEmailNotifications"
                                        >
                                            {"I allow notification via email"}
                                        </label>
                                    }
                                />
                                <CheckboxControlled
                                    name="allowPushNotifications"
                                    control={control}
                                    disabled={isSubmitting}
                                    label={
                                        <label
                                            className="checkbox-label ml-2"
                                            htmlFor="allowPushNotifications"
                                        >
                                            {"I allow browser notification"}
                                        </label>
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="col-6 p-5 pb-0 pt-0">
                        {SHOW_PERSONAL_ID_VERIFICATION && (
                            <>
                                <Header4>{translate("personal-id-verifiaction")}</Header4>
                                <Field>
                                    <SecondaryButton
                                        type={"button"}
                                        className={"secondary-button--w-full"}
                                        onClick={onPathVerificationClick}
                                        label={translate("pass-verification-button")}
                                        disabled={isSubmitting}
                                    />
                                </Field>

                                <TextField id={"id-number"} disabled={true} value={"MR54654564"} />
                            </>
                        )}
                    </div>
                </div>
            </FormContent>
            <AccountFormFooter
                disabled={isSubmitting || !isDirty}
                onCancelClick={() => {
                    reset(defaultValues);
                }}
                onSaveClick={() => {
                    console.log("save");
                }}
            />
        </form>
    );
});
