import {action, makeAutoObservable, observable} from "mobx";
import {RootStore} from "../RootStore";
import {Booking} from "../../services/interfaces";

export class RouteStore {
    public activeRoute = "";
    public previousRoute = "";
    public intendedRoute: string | null = null;
    public intendedBooking: Booking | null = null;

    constructor(protected rootStore: RootStore) {
        makeAutoObservable(this, {
            activeRoute: observable,
            previousRoute: observable,
            intendedRoute: observable,
            intendedBooking: observable,
            setActiveRoute: action,
            setPreviousRoute: action,
            setIntendedRoute: action,
            setIntendedBooking: action
        });
    }

    setActiveRoute(route: string) {
        this.previousRoute = this.activeRoute;
        this.activeRoute = route;
    }

    setPreviousRoute(route: string) {
        this.previousRoute = route;
    }

    setIntendedRoute(route: string | null) {
        this.intendedRoute = route;
    }

    isActive(route: string) {
        return this.activeRoute === route;
    }

    buildUrl(baseUrl: string, params: Record<string, string>) {
        const searchParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            if (value) searchParams.append(key, value);
        });
        const queryString = searchParams.toString();
        return queryString ? `${baseUrl}?${queryString}` : baseUrl;
    }

    setIntendedBooking(booking: Booking | null) {
        this.intendedBooking = booking;
    }
}
