import {InvalidDateError} from "./InvalidDateError";
import {EuroDateString} from "./types";

// Парсинг строки в Date
export function parseEuroDate(dateStr: EuroDateString): Date {
    const isValidFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateStr);
    if (!isValidFormat) {
        throw new InvalidDateError("Date string must be in format dd-MM-yyyy");
    }

    const [dayStr, monthStr, yearStr] = dateStr.split("-");
    const day = parseInt(dayStr, 10);
    const month = parseInt(monthStr, 10) - 1;
    const year = parseInt(yearStr, 10);

    if (month < 0 || month > 11) {
        throw new InvalidDateError("Month must be between 1 and 12");
    }
    if (day < 1 || day > 31) {
        throw new InvalidDateError("Day must be between 1 and 31");
    }
    if (year < 1900 || year > 9999) {
        throw new InvalidDateError("Year must be between 1900 and 9999");
    }

    const date = new Date(year, month, day);

    if (date.getMonth() !== month) {
        throw new InvalidDateError("Invalid date for given month");
    }

    return date;
}
