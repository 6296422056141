import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import RegisterFormPersonalID from "../../components/forms/RegisterFormPersonalID";
import {LoginForm} from "../../components/forms/LoginForm";
import {NewPasswordForm} from "../../components/forms/NewPasswordForm";
import RegisterFormPersonalIDUpload from "../../components/forms/RegisterFormPersonalIDUpload";
import {ProfilePersonalInfoForm} from "../../components/forms/ProfilePersonalInfoForm";
import {AccountTabs} from "../../components/tabs/AccountTabs";
import {ResetPasswordForm} from "../../components/forms/ResetPasswordForm";
import RegisterFormGeneralInfo from "../../components/forms/RegisterFormGeneralInfo";
import {PagesId} from "../../constants/PAGES_ID";
import {useRootStore} from "../../store/RootStore";
import {Navigate} from "react-router-dom";
import {DefaultHomePageUrl} from "../../constants";

const pageId = PagesId.Login;

const LoginPage = observer(() => {
    const {userStore} = useRootStore();

    if (userStore.isLoggedIn) {
        return <Navigate to={DefaultHomePageUrl} />;
    }

    const onSuccessLogin = () => {
        console.log("Login successful");
    };

    const onErrorLogin = () => {
        console.log("Login failed");
    };

    return (
        <PageContent className={"page-login"} pageId={pageId}>
            {/* // Todo remove landing login dialog */}
            {/* <LandingLoginForm /> */}
            <LoginForm onSuccessLogin={onSuccessLogin} onErrorLogin={onErrorLogin} />
            {/* <LoginForm />
                <NewPasswordForm />
                <ResetPasswordForm />
                <RegisterFormGeneralInfo />
                <RegisterFormPersonalIDUpload />
                <AccountTabs /> */}
        </PageContent>
    );
});

export default LoginPage;
