import {ENV_CONST} from "../../constants";
import {handleErrorException} from "../../helpers";
import {createHttpClient} from "../createHttpClient";
import {IUserData, IUserResponse} from "../interfaces";

const endpoint = "user";
const {API_URL} = ENV_CONST;

const getUnexpectedErrorUserResponse = (): IUserResponse => ({
    data: null,
    errors: [{message: "Unexpected user response error"}]
});

const getInvalidSessionTokenUserResponse = (): IUserResponse => ({
    data: null,
    errors: [{message: "Invalid session token"}]
});

const httpClient = createHttpClient(API_URL);

export async function getUserData(
    session_token?: string | null,
    skipUnauthorizedHandler = false
): Promise<IUserResponse> {
    if (!session_token) {
        return getInvalidSessionTokenUserResponse();
    }

    let result: IUserResponse = getUnexpectedErrorUserResponse();

    try {
        result = await httpClient.get<IUserResponse>(endpoint, session_token, {
            skipUnauthorizedHandler
        });
    } catch (e) {
        const message = handleErrorException(e);
        result.errors?.push({message});
        console.error(result.errors);
    }

    return result;
}

export async function updateUserData(
    data: IUserData,
    session_token?: string | null
): Promise<IUserResponse> {
    console.log("updateUserData", data, session_token);
    if (!session_token) {
        return getInvalidSessionTokenUserResponse();
    }

    let result: IUserResponse = getUnexpectedErrorUserResponse();

    try {
        result = await httpClient.put<IUserResponse>(endpoint, data, session_token);
    } catch (e) {
        const message = handleErrorException(e);
        result.errors?.push({message});
        console.error(result.errors);
    }

    return result;
}
