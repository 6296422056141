import {AppDateFormat} from "./types";

export const formatDate = (date: Date | null, format: AppDateFormat = "dd-MM-yyyy"): string => {
    if (!date) return "00-00-0000";

    switch (format) {
        case "dd-MM-yyyy": {
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        default: {
            throw new Error(`Unsupported date format: ${format}`);
        }
    }
};
